import React from "react"

// Components
import Panel from "../components/Panel"
import Layout from "../components/Layout"

export default function LS102() {
  return (
    <Layout>
      <Panel building="102" />
    </Layout>
  )
}
